.Collapsible {
  border-bottom: 1px solid grey;
  margin-bottom: 1px;
  display: flex;
  font-size: 20px;
  flex-direction: column;
  padding: 6px 9px;
  text-align: center;
}

.Collapsible__contentInner {
  font-size: 16px;
}

.Collapsible__trigger {
  cursor: pointer;
  font-weight: 500;
  color: #aaa;
  font-size: 15px;
}

@media (max-width: 700px) {
  .Collapsible__trigger {
    font-size: 16px;
    /* font-weight: 00; */
  }
}

@media (min-width: 701px) {
  .Collapsible {
    padding: 9px 9px;
  }
}
